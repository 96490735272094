<template>
    <div class='login-form'>
        <div>
            <img src='https://uploads-ssl.webflow.com/61373db96e1a1814e5edae40/61373db96e1a18e76fedae89_logo_rentateam.svg' alt='RentaTeam' />
        </div>
        <h1 class='login-form__hero'>
            Need awesome message
        </h1>
        <div class='login-form__button'>
            <button @click='getGoogleAuthCode()' class='btn btn-primary btn-sm'>
                <i class='fab fa-google login-form__button-icon'></i> Sign in with Google
            </button>
        </div>
    </div>
</template>

<style>
.login-form {
    margin: auto;
    display: flex;
    flex-direction: column;
}
.login-form__button {
    margin-top: 1rem;
    align-self: center;
}
.login-form__button-icon {
    margin-right: 0.5rem;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';

export default {
    computed: {
        ...mapState('auth', [
            'user',
        ]),
        ...mapGetters('auth', [
            'isAuthenticated',
        ]),
    },
    methods: {
        ...mapActions('auth', [
            'loginGoogle',
        ]),
        async getGoogleAuthCode() {
            const code = await this.$gAuth.getAuthCode();
            return this.loginGoogle(code);
        },
    },
    created() {
        if (this.isAuthenticated) {
            router.push('/');
        }
    },
};
</script>
